import { forwardRef } from "react";
import { iconHref, type IconName } from "./icon.names";
import { iconVariants, type IconVariants } from "./icon.styles";

export type IconRef = React.ElementRef<"svg">;

export interface IconProps
  extends Omit<
      React.ComponentPropsWithoutRef<"svg">,
      "width" | "height" | "viewBox" | "fill"
    >,
    IconVariants {
  name: IconName;
}

const Icon = forwardRef<IconRef, IconProps>(
  ({ className, inline, name, palette, size, ...props }, ref) => {
    return (
      <svg
        focusable="false"
        aria-hidden="true"
        {...props}
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        fill="currentColor"
        className={iconVariants({ className, inline, palette, size })}
        ref={ref}
      >
        <use href={`${iconHref}#${name}`} />
      </svg>
    );
  },
);

Icon.displayName = "Icon";

export { Icon };
